import { Button } from "../../../components/Button";
import { Modal } from "../../../components/Modals/Modal";

export function ExperienceSaveAlert({
  open,
  onClose,
  title,
}: {
  open: boolean;
  onClose: () => void;
  title: string;
}) {
  return (
    <Modal
      title={title}
      open={open}
      onClose={onClose}
      showCloseButton={false}
      contentClassName="[&_h1]:text-xl [&_.close]:w-5 [&_.close]:h-5 !max-w-[375px] [&>.header]:mb-2"
    >
      <p className="text-[#33363F] text-base">
        Categories are required to save.
      </p>
      <div className="flex gap-3 mt-6 justify-end">
        <Button
          onClick={onClose}
          className="!text-base !font-bold !w-[100px] !rounded-full [&_.outline-variant]:!rounded-full"
        >
          Ok
        </Button>
      </div>
    </Modal>
  );
}
